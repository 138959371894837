<template>
<v-container fluid class="pa-0">
    <!-- Formulaire -->
    <fieldset fluid class="mt-5 ma-2 pa-0 elevation-0 rounded-xxl micorise-v-card"> <!-- style="background-color:#E0F2F1" -->
        <legend style="margin-left: 1em; padding: 0.2em 0.8em ">Gestion de l'abonnement</legend>
    <v-card fluid class="ma-2 pa-3 elevation-0 rounded-xxl">
        <v-row no-gutters>
            <v-col cols="12" md="12" class="pr-2">
                <v-text-field outlined dense class="rounded-lg" label="Dénomination sociale"  v-model="item.cpy_name"></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="pr-2">
                <v-text-field outlined dense class="rounded-lg" label="Adresse e-mail" v-model="item.cpy_mail"></v-text-field>
            </v-col>
            <!-- -------------------------- -->
            <v-col cols="6" md="6" v-if="$root.$permissions?.Company?.delete" class="pr-2">
                <v-select outlined dense class="rounded-lg"
                    item-text="sta_label" item-value="sta_id"
                    :items="stateListByGroup['GEN']" v-model="item.cpy_sta_id" label="Statut" single-line >
                </v-select>
            </v-col>
            <v-col cols="6" md="6">
                <v-select item-text="label" item-value="value" outlined dense class="rounded-lg"
                    :items="cacheState" v-model="item.cpy_sub_typ_id" label="Abonnement" single-line persistent-hint hint="Type d'abonnement">
                </v-select>
            </v-col>
            <v-col cols="6" md="6">
                <v-btn class="ml-2" v-if="item.cpy_active" :disabled="!item.cpy_id" rounded color="success" @click.native="saveItem();"><v-icon left dark>mdi-content-save-check-outline</v-icon>Changer d'offre</v-btn>
                <v-btn class="ml-2" v-if="item.cpy_active" :disabled="!item.cpy_id" rounded color="error" @click.native="cancelSub();"><v-icon left dark>mdi-close</v-icon>Se désabonner</v-btn>
                <v-btn class="ml-2" v-if="!item.cpy_active" :disabled="!item.cpy_id" rounded color="success" @click.native="activeAccount();"><v-icon left dark>mdi-content-save-check-outline</v-icon>Ré-activer mon compte avec cette d'offre</v-btn>
            </v-col>
            <v-col cols="12" md="12" v-if="!item.cpy_active">
                <v-alert border="top" colored-border type="info" elevation="2">
                    Votre compte est actuellement innactif, cliquez sur le boutton "Ré-activer mon compte. ceci va générer la prochaine facture"
            </v-alert>
            </v-col>
        </v-row>
    </v-card>
    <v-app-bar flat color="transparent" class="elevation-0">
        <v-spacer></v-spacer>
    </v-app-bar>
    </fieldset>
    <fieldset fluid class="mt-5 ma-2 pa-0 elevation-0 rounded-xxl micorise-v-card"> <!-- style="background-color:#E0F2F1" -->
        <legend style="margin-left: 1em; padding: 0.2em 0.8em ">Denières factures</legend>
        <v-card fluid class="ma-2 pa-3 elevation-0 rounded-xxl">
            <v-data-table v-bind:headers="headers" :items="invoices" 
            items-per-page-text="Lignes par page" :hide-default-header="false" :items-per-page-options="pagination.rowsPerItem"
            :loading="loading.fetchData" loading-text="Chargement... Veuillez patienter">
            
                <template v-slot:[`item.ref`]="{ item }">
                <strong flat color="blue" class="ma-0 subheading text-uppercase blue--text">{{ item.ref }}</strong>
                </template>
                <template v-slot:[`item.date`]="{ item }">
                    <v-chip label outlined>{{ (item.date) ? $date(item.date).format('DD/MM/YYYY') : '---/---/--------' }}</v-chip>
                </template>
                <template v-slot:[`item.total_ht`]="{ item }">{{ parseFloat(item.total_ht).toFixed(2) }}</template>
                <template v-slot:[`item.total_tva`]="{ item }">{{ parseFloat(item.total_tva).toFixed(2) }}</template>
                <template v-slot:[`item.total_ttc`]="{ item }">{{ parseFloat(item.total_ttc).toFixed(2) }}</template>
                <template v-slot:[`item.last_main_doc`]="{ item }">
                    <v-btn outlined small color="green" class="ml-2 elevation-1" @click="downloadInvoice(item.last_main_doc)">
                        <v-icon left class="mr-2">mdi-file-pdf-box</v-icon>
                    </v-btn>
                    <v-btn v-if="item.paye == '0'" outlined small color="orange" class="ml-2 elevation-1" target="_blank" :href="'https://facturation.micorise.fr/public/payment/newpayment.php?source=invoice&ref=' + item.ref">
                        <v-icon left class="mr-2">mdi-credit-card-plus-outline</v-icon>Payer
                    </v-btn>
                    <v-btn v-else outlined small color="green" class="ml-2 elevation-1">
                        <v-icon left class="mr-2">mdi-credit-card-check-outline</v-icon>
                    </v-btn>
                </template>
                <template slot="pageText" slot-scope="{ pageStart, pageStop }">
                De {{ pageStart }} à {{ pageStop }}
                </template>
            </v-data-table>
        </v-card>
    </fieldset>

</v-container>
</template>
    
<script>
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useCompanyStore } from '@/stores/company'

import { useTypeStore } from '@/stores/type'
import { useStateStore } from '@/stores/state'
import { useUserStore } from '@/stores/user'

export default {
    name: 'companies-edit',
    props: [ ],
    components: { },
    data: function () {
        return {
            loading: { fetchData: true, saveProgress: false },
            search: null, searchAddress: null, searchBoxClosed: true, addressTab: [], timeOutHandle: 'off',
            cacheState: [ 
                { label: 'Folium (basic)',         id: 'FO', value: 'SUB02' },
                { label: 'Arbor (Intermédaire)',   id: 'AR', value: 'SUB03' },
                { label: 'Silva (Toutes options)', id: 'SI', value: 'SUB04' },
                { label: 'Seminis (Etudiants)',    id: 'SE', value: 'SUB01', disabled:true },
            ],
            invoices: [],
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
                { text: 'Ref',              align: 'left', value: 'ref'},
                { text: 'Total ht',         align: 'left', value: 'total_ht'},
                { text: 'Total tva',        align: 'left', value: 'total_tva'},
                { text: 'Total ttc',        align: 'left', value: 'total_ttc'},
                { text: 'Date',             align: 'left',   value: 'date'},
                { text: 'Télécharger',       align: 'left',   value: 'last_main_doc'},
            ],
        }
    },
    computed: {
        ...mapWritableState(useCompanyStore, {item: 'item' }),
        ...mapState(useTypeStore, {typeListByGroup: 'itemListByGroup', typeListById: 'itemListById' }),
        ...mapState(useStateStore, {stateListByGroup: 'itemListByGroup', stateListById: 'itemListById' }),
    },
    async mounted (){
        await this.fetchItem( this.$route.params.cpy_id, '?ent_sta_id=GEN01' )
        await this.getInvoices()
    },
    methods: {
        ...mapActions(useCompanyStore, {fetchItem: 'fetchItem', updateItem: 'updateItem', storeItem: 'storeItem', destroyItem: 'destroyItem'}),
        ...mapActions(useUserStore,      { fetchMe: 'fetchMe'}),
        cancelSub(){
            this.$root.$confirm('Se désabonner', 'Confirmer l\'annunlation ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.item.cpy_sta_id='GEN03'
                    this.saveItem()
                }
            })
        },
        activeAccount(){
            this.$root.$confirm('Confirmer', 'Confirmer l\'activation du compte ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.updateActiveAccount()
                }
            })
        },
        async updateActiveAccount(){
            this.invoices = []
            this.loading.fetchData = true
            this.item.cpy_sta_id='GEN01'
            this.item.cpy_active = 1
            await this.updateItem()
            await this.fetchMe('?include=usercompany&extended=withsponsorshipcount')
            this.$root.$userInfo    = this.user
            setTimeout(() => {
                this.getInvoices()
            }, 3000);
        },
        async saveItem () {
            // Enregistrement du formulaire
            this.loading.fetchData = true
            if( parseInt( this.$route.params.cpy_id ) > 0 ){
                this.updateItem()
            } else {
                this.storeItem()
            }
            this.loading.fetchData = false
            this.$router.push({ path: '/companies' })
        },
        async deleteItem(){
            this.$root.$confirm('Supprimer', 'Confirmer suppression ?', { color: 'red', width: 400 }).then((confirm) => {
                if( confirm ){
                    this.destroyItem().then( () => {
                        this.$router.push({ path: '/companies' })
                    })
                }
            })
        },
        getInvoices(){
            return new Promise( (resolve) => {
                this.$http.get('/companies/indexinvoices/' + this.item.cpy_uid + '/?per_page=false&extended=viewall,withcountuser,withcounttask').then( (response) => {
                    this.invoices = response.data
                    this.loading.fetchData = false
                    resolve('resolved')
                })
            })
        },
        downloadInvoice(id){
            this.$http.customRequest({ method: 'get', url: encodeURI('/companies/' + this.item.cpy_uid + '/downloadinvoice/?facture=' + id), responseType: 'blob' } ).then( (response) => {
                let filename      = 'd.pdf'
                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(response.headers['content-disposition']);
                if (matches != null && matches[1]) { 
                    filename = matches[1].replace(/['"]/g, '');
                }
                if( response.status == 200 ){
                    this.$download(response.data, filename, response.headers['content-disposition'])
                } else {
                    this.$root.$toast({ color: 'error', text: 'Fichier non trouvé.' })
                }
            })
        }

    }
}
</script>